import React, { useEffect } from 'react';
// import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import AOS from 'aos';

import Header from './header';
import Footer from './footer';

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  // TODO: find a better spot so it does initialize on every page load?
  useEffect(() => {
    AOS.init({
      once: true,
      offset: -30,
    });
  });

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap" // TODO: this be too much
          rel="stylesheet"
        />
        <body className="text-gray-900 font-body" />
      </Helmet>

      <Header siteTitle="Nolan Oak" />

      <main>{children}</main>

      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
