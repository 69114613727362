import React from 'react';
import { Link } from 'gatsby';

import logo from '../images/logo.svg';

const MobileNav = ({ mobileNavIsVisible, setMobileNavIsVisible, siteTitle, pages }) => {
  return (
    <div
      className={
        'fixed inset-0 z-10 flex items-start justify-center p-2 bg-gray-100 ' +
        (mobileNavIsVisible ? '' : 'hidden')
      }
    >
      <div className="relative w-full max-w-lg p-6 bg-white border rounded-md shadow">
        <button
          type="button"
          className="absolute top-0 right-0 flex items-center justify-center w-8 h-8 mt-4 mr-4 text-gray-600 rounded hover:bg-gray-100"
          onClick={() => setMobileNavIsVisible(false)}
        >
          <svg fill="currentColor" className="w-6 h-6" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>

        <Link to="/" className="inline-flex items-center ">
          <img src={logo} className="w-8 h-8" alt="logo" />
          <h1 className="ml-2 text-2xl font-black ">{siteTitle}</h1>
        </Link>

        <nav className="mt-4 mr-8">
          <ul>
            {pages.map((page) => (
              <li key={page.title}>
                <Link
                  to={page.to}
                  className="flex py-2 text-lg font-semibold text-gray-700 hover:text-gray-900"
                >
                  {page.title}
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        <div className="mt-6">
          <Link
            to="/pricing#launch"
            className="flex justify-center w-full px-5 py-3 text-lg font-semibold text-white bg-blue-500 rounded-md hover:bg-opacity-75"
          >
            Launch your website
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
