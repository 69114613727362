import React, { useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Container from '../components/container';
import MobileNav from './mobileNav';

import logo from '../images/logo.svg';

const Header = ({ siteTitle }) => {
  const [mobileNavIsVisible, setMobileNavIsVisible] = useState(false);

  const pages = [
    {
      title: 'Pricing',
      to: '/pricing',
    },
    // {
    //   title: 'How It Works',
    //   to: '/how-it-works',
    // },
    // {
    //   title: 'Examples',
    //   to: '/examples',
    // },
    // {
    //   title: 'Blog',
    //   to: '/blog',
    // },
    {
      title: 'Contact Us',
      to: '/contact-us',
    },
  ];

  return (
    <>
      <MobileNav
        mobileNavIsVisible={mobileNavIsVisible}
        setMobileNavIsVisible={setMobileNavIsVisible}
        siteTitle={siteTitle}
        pages={pages}
      />

      <header>
        <Container>
          <div className="flex items-center justify-between py-4">
            <div className="flex items-center">
              <Link to="/" className="inline-flex items-center">
                <img src={logo} className="w-8 h-8" alt="logo" />
                <h1 className="ml-2 text-2xl font-black">{siteTitle}</h1>
              </Link>

              <nav className="hidden ml-6 lg:flex">
                <ul className="flex">
                  {pages.map((page) => (
                    <li key={page.title}>
                      <Link
                        to={page.to}
                        className="px-4 py-4 font-medium text-gray-700 hover:text-gray-900"
                      >
                        {page.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>

            <div>
              <Link
                to="/pricing"
                className="hidden px-5 py-3 text-sm font-semibold text-white bg-blue-500 rounded-md hover:bg-opacity-75 lg:flex"
              >
                Launch your website
              </Link>

              <button
                type="button"
                className="flex items-center justify-center lg:hidden"
                onClick={() => setMobileNavIsVisible(true)}
                aria-label="navigation"
              >
                <svg fill="currentColor" className="w-8 h-8" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </Container>
      </header>
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
