import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';

function SEO({ title, description: metaDescription, meta, image: metaImage, article, lang }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            tagLine
            description
            logo
            ogImage
            social {
              twitter
              facebook
              instagram
            }
          }
        }
      }
    `,
  );

  const { site } = data;
  const description = metaDescription || site.siteMetadata.description;
  const image = `${site.siteMetadata.siteUrl}${
    metaImage ? metaImage.src : site.siteMetadata.ogImage
  }`;
  const logo = site.siteMetadata.logo;
  const { pathname } = useLocation();
  const canonical = `${site.siteMetadata.siteUrl}${pathname}`;

  const baseSchema = [
    {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: site.siteMetadata.title,
      url: site.siteMetadata.siteUrl,
      logo,
      sameAs: [
        `https://facebook.com/${site.siteMetadata.social.facebook}`,
        `https://twitter.com/${site.siteMetadata.social.twitter}`,
        `https://instagram.com/${site.siteMetadata.social.instagram}`,
      ],
    },
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      name: site.siteMetadata.title,
      url: canonical,
    },
  ];
  const schema = article
    ? [
        ...baseSchema,
        {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              name: `${site.siteMetadata.title} Blog`, // TODO?
              item: `${site.siteMetadata.siteUrl}/blog`, // TODO: don't hardcode blog?
            },
            {
              '@type': 'ListItem',
              position: 2,
              name: title,
              item: canonical,
            },
          ],
        },
        {
          '@context': 'https://schema.org',
          '@type': 'BlogPosting',
          mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': canonical,
          },
          headline: title,
          description,
          image,
          author: {
            '@type': 'Person',
            name: 'stephen', // TODO
          },
          publisher: {
            '@type': 'Organization',
            name: site.siteMetadata.title,
            logo: {
              '@type': 'ImageObject',
              url: logo,
            },
          },
          datePublished: '2020-08-23', // TODO
          dateModified: '2020-08-26', // TODO
        },
      ]
    : baseSchema;

  return (
    <Helmet
      encodeSpecialCharacters={true} // TODO: do we want this on??? https://github.com/nfl/react-helmet/issues/333
      htmlAttributes={{
        lang,
      }}
      title={title} // TODO: this may only show the page as e.g., "Home" when sharing/favoriting
      titleTemplate={`%s | ${site.siteMetadata.tagLine} | ${site.siteMetadata.title}`} // TODO: set a more descriptive page title
      link={[
        {
          rel: 'canonical',
          href: canonical,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: `og:site_name`,
          content: site.siteMetadata.title,
        },
        {
          property: 'og:url',
          content: canonical,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: article ? 'article' : 'website',
        },
        {
          property: 'og:image',
          content: image,
        },
        {
          property: 'og:image:width',
          content: image.width,
        },
        {
          property: 'og:image:height',
          content: image.height,
        },
        {
          name: 'og:image:alt',
          content: description,
        },
        {
          property: 'og:local',
          content: `en_US`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        // TODO: make these creator conditional?
        // {
        //   name: `twitter:creator`,
        //   content: `@${site.siteMetadata.social.twitter}`, // TODO: maybe make this blank if this isn't a blog post?
        // },
        {
          name: `twitter:site`,
          content: `@${site.siteMetadata.social.twitter}`,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:image',
          content: image,
        },
        {
          name: 'twitter:image:alt',
          content: description,
        },
      ].concat(meta)}
      script={[
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify(schema),
        },
      ]}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  article: false,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  article: PropTypes.bool,
};

export default SEO;
